.bartImage {
  display: inline-block;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 32px;
  height: 18px; 
}

.bartText {
  display: inline;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.hr {
  border: 2px solid rgb(179, 179, 179);
}

.PostButtonCol {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.vaccineImage {
  display: inline-block;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 32px;
  height: 32px; 
  position: absolute;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.vaccineImageMobile {
  width: 32px;
  height: 32px;
  align-content: right;
  justify-content: right;
}

.cardBigInfo {
  display: block;
  font-size: 26px;
  font-weight: 300;
}

.cardMediumInfo {
  display: block;
  font-size: 20px;
  font-weight: 300;
}

.cardSmallInfo {
  display: block;
  font-size: 18px;
  font-weight: 300;
}
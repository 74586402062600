.homeContainer {
    margin: 25px, 0px, 25px, 0px;
    display: inline;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.inlineSpan {
    display: inline
}

.editButtons {
    margin-left: 10px;
    float: right;
}

.editForm {
    margin-left: 24px !important;
}

.supervisorPosition {
    font-size: 20px;
    margin-left: 24px;
}

.phoneLink {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.removeMargin {
    margin-bottom: 0;
}
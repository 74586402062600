.FormEntries {
    padding-right: 40px !important;
}

input[type="text"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="select"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="number"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="date"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="time"]::-webkit-input-placeholder {
    color: #d5dbd6;
}
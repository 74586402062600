.NotificationCenterCard {
    margin: auto;
    text-align: center;
}

.LinkButtons {
    margin: auto !important;

}

.LoadingWheel {
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.ButtonBottom {
    position: absolute;
    bottom: 20px;
}

.bigButton {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.modifiedSpan {
    display: block;
}

.PostButtonCol {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    /* justify-content: start; */
}

.topMobileContainer {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}


a {
    margin-left: 15px;
    margin-bottom: 10px;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}
  
a:hover {
    background-color: #ddd;
    color: black;
}
  
.previous {
    background-color: #f1f1f1;
    color: black;
}

.cardBigInfoMobile {
    display: block;
    font-size: 20px;
    font-weight: 300;
}

.cardMediumInfoMobile {
    display: block;
    font-size: 18px;
    font-weight: 300;
}

.cardSmallInfoMobile {
    display: block;
    font-size: 16px;
    font-weight: 300;
}

  
.vaccineImageSmall {
    display: inline-block;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 6px;
}

.buttonAlign {
    position: absolute;
    right: 0px;
    margin-right: 50px;
}
.customDashboardContainer {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 500px;
    margin-right: 500px;
}

.customDashboardContainerMobile {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.submitButton {
    margin-left: 15px;
    margin-bottom: 10px;
}

.feedbackIcon {
    margin-bottom: 5px;
    margin-left: 5px;
}
.ButtonCol {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.h2 {
    margin-top: 0px;
    border: 1px solid red;
    outline: 5px dotted green;
  }

.banner {
    background-color:#007bff;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
}

.customDashboardContainer {
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;
}

.customDashboardContainer1 {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 150px;
    margin-right: 150px;
}

.bigButton {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.returnButton {
  margin-top: 10px;
  background-color: #f1f1f1;
  color: black;
}

.pulse {
    margin:100px;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #cca92c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 5s infinite;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
.removeRowMargin {
    margin-bottom: 0px;
}

.cardMargins {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 8px;
    padding-top: 10px;
    padding-right: 8px;
    padding-bottom: 10px;
}

.reviewCard {
    margin-left: 5px;
    margin-right: 5px;
}
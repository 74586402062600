.rightCard {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 40px 20px 40px;
    width: 250px;
}

.greenDot {
    text-align: right;
    margin-top: 15px;
    margin-left: 5px;
}

.formRows {
    margin-bottom: 0px;
}
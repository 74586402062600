.FullOpacity {
    opacity: 100 !important;
}

.OriginalValue {
    width: 50%;
    margin-top: 7px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 14px;
}

.PencilIcon {
    width: 20px;
    height: 20px;
    margin-top: 4px;
}

.NewValue {
    margin-top: -8px;
    margin-right: 20px;
    margin-bottom: 0px !important;
}

.RemoveRowMargin {
    margin-bottom: 10px;
    margin-top: -5px;
}

.ButtonSpacing {
    margin-left: 5px;
}

.ErrorMessage {
    margin-left: 20px;
}

.ButtonLine {
    margin-left: 0px !important;
    margin-bottom: 5px;
    width: 100%;
}

.BooleanButton {
    float: right;
}

.ProfileCard {
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}
.CalendarView {
    margin: 25px 25px 25px 25px;
}

.ActionsCard {
    margin-left: 0px !important;
}

.ActionsCardHeader {
    margin-left: 0px !important;
    margin-bottom: 5px !important;
}

.ActionsEmailText {
    color: #8F8F8F;
    margin-left: 51px;
    margin-top: 6px;
}

.ActionsCheckmark {
    color: green;
    margin-left: 6px;
    margin-top: 9px;
}

.ActionsCheckmarkEmail {
    color: green;
    margin-top: 9px;
    margin-right: 3px;
}

.ActionsFailureX {
    color: red;
    margin-left: 3px;
    margin-top: 9px;    
}

.ActionsMarkText {
    color: #8F8F8F;
    margin-left: 20px;
    margin-top: 6px;
}

.ActionsMarkTextNumber {
    color: #8F8F8F;
    margin-left: 5px;
    margin-top: 6px;
}

.ClipboardButtons {
    margin-right: 3px;
}
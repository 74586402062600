.ButtonCol {
    margin: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}


.ManageCard {
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.RoleButtons {
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px; 
}

.ResolveDiv {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.ResolveButton {
    width: 80%;
    margin-bottom: 24px;
}

/* For larger screens (md and above) */
@media (min-width: 768px) {
    .first-column {
        padding-left: 0;  /* No left padding for the first column */
    }

    .middle-column {
        padding-left: 15px; /* Left and right padding for middle column */
        padding-right: 15px;
    }

    .last-column {
        padding-right: 0; /* No right padding for the last column */
    }
}

/* For smaller screens (below md) */
@media (max-width: 767px) {
    .first-column, .middle-column, .last-column {
        padding-left: 0 !important;  /* No padding on the left */
        padding-right: 0 !important; /* No padding on the right */
    }
}
.CalendarView {
    margin: 25px 25px 25px 25px;
}

.Jumbo {
    margin: 0px 75px 75px 75px;
}

.MobileJumbo {
    margin-bottom: 0px !important;
}

a {
    margin-left: 15px;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}
  
a:hover {
    background-color: #ddd;
    color: black;
}
  
.previous {
    background-color: #f1f1f1;
    color: black;
}
.ErrorBox {
    margin-top: 100px;
    margin-left: 60px;
    margin-right: 60px;
    padding-left: 50px;
    padding-right: 50px;
}

.ErrorBoxMobile {
    margin-top: 200px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.Oops {
  margin: auto;
  padding: 10px;
}

.MMALogo {
    height: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
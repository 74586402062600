.removeRowMargin {
    margin-bottom: 0px;
}

.ellipses {
    margin-top: 7px;
    margin-left: 5px;
}

.noShowText {
    margin-left: 17px;
    margin-right: 30px;
}

.noShowButton  {
    margin-right: 20px;
}

.user-details {
    margin-bottom: 10px;
}

.details-group {
    display: flex;
    flex-direction: column;
}

.details-group a {
    margin-left: 1;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
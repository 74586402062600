.mmaImage2 {
    display: inline-block;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 106px;
    height: 55px; 
  }

.SchedulerLogo {
    font-size: 2.1rem;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}

.SchedulerLogoMobile {
    font-size: 2.1rem;
    display: block;
    text-align: center;
    margin-left: 25%;
    margin-right: auto;
    margin-bottom: 0px;
    padding: 0;
}

.noHover {
    background-color: unset !important;
    color: unset !important;
    text-decoration: none !important;
}

.HeaderRow {
    margin-bottom: 0px !important;
}

.HeaderCol {
    padding: 0px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ErrorBox {
    margin-top: 100px;
    margin-left: 60px;
    margin-right: 60px;
    padding-left: 50px;
    padding-right: 50px;
}

.ErrorBoxMobile {
    margin-top: 200px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.Oops {
  margin: auto;
  padding: 10px;
}

.MMALogo {
    height: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.mmaImage2 {
    display: inline-block;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 106px;
    height: 55px; 
  }

.SchedulerLogo {
    font-size: 2.1rem;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}

.SchedulerLogoMobile {
    font-size: 2.1rem;
    display: block;
    text-align: center;
    margin-left: 25%;
    margin-right: auto;
    margin-bottom: 0px;
    padding: 0;
}

.noHover {
    background-color: unset !important;
    color: unset !important;
    text-decoration: none !important;
}

.HeaderRow {
    margin-bottom: 0px !important;
}

.HeaderCol {
    padding: 0px !important;
}
.style {
    text-align: "center";
    padding: "20px";
    position: "fixed";
    left: "0";
    bottom: "0";
    height: "60px";
    width: "100%";
}
.homeContainer {
    margin: 25px, 0px, 25px, 0px;
    display: inline;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.inlineSpan {
    display: inline
}
.CalendarView {
    margin: 25px 25px 25px 25px;
}

.Jumbo {
    margin: 0px 75px 75px 75px;
}

.MobileJumbo {
    margin-bottom: 0px !important;
}

a {
    margin-left: 15px;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}
  
a:hover {
    background-color: #ddd;
    color: black;
}
  
.previous {
    background-color: #f1f1f1;
    color: black;
}
.CalendarView {
    margin: 25px 25px 25px 25px;
}

.Jumbo {
    margin: 0px 75px 75px 75px;
}

a {
    margin-left: 15px;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}
  
a:hover {
    background-color: #ddd;
    color: black;
}
  
.previous {
    background-color: #f1f1f1;
    color: black;
}
.NotificationCenterCard {
    margin: auto;
    text-align: center;
}

.LinkButtons {
    margin: auto !important;

}

.LoadingWheel {
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.ButtonBottom {
    position: absolute;
    bottom: 20px;
}

.no-padding {
    padding: 0;
}

.vaccineImageSmall {
    display: inline-block;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 6px;
}

.buttonAlign {
    position: absolute;
    right: 0px;
    margin-right: 50px;
}
/**
 * Main wrapper
 */
.select-search {
    width: 300px;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2FCC8B;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
.rightCard {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 40px 20px 40px;
    width: 250px;
}

.greenDot {
    text-align: right;
    margin-top: 15px;
    margin-left: 5px;
}

.formRows {
    margin-bottom: 0px;
}
.rightJustify {
    position: absolute;
    right: 0px;
}
.details {
  font-size: 20px;
}

.link {
  font-size: 20px;
}
.removeRowMargin {
    margin-bottom: 0px;
}

.ellipses {
    margin-top: 7px;
    margin-left: 5px;
}

.noShowText {
    margin-left: 17px;
    margin-right: 30px;
}

.noShowButton  {
    margin-right: 20px;
}

.user-details {
    margin-bottom: 10px;
}

.details-group {
    display: flex;
    flex-direction: column;
}

.details-group a {
    margin-left: 1;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
.ButtonCol {
    margin: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}


.ManageCard {
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.RoleButtons {
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px; 
}

.ResolveDiv {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.ResolveButton {
    width: 80%;
    margin-bottom: 24px;
}

/* For larger screens (md and above) */
@media (min-width: 768px) {
    .first-column {
        padding-left: 0;  /* No left padding for the first column */
    }

    .middle-column {
        padding-left: 15px; /* Left and right padding for middle column */
        padding-right: 15px;
    }

    .last-column {
        padding-right: 0; /* No right padding for the last column */
    }
}

/* For smaller screens (below md) */
@media (max-width: 767px) {
    .first-column, .middle-column, .last-column {
        padding-left: 0 !important;  /* No padding on the left */
        padding-right: 0 !important; /* No padding on the right */
    }
}
.homeContainer {
    margin: 25px, 0px, 25px, 0px;
    display: inline;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.inlineSpan {
    display: inline
}

.editButtons {
    margin-left: 10px;
    float: right;
}

.editForm {
    margin-left: 24px !important;
}

.supervisorPosition {
    font-size: 20px;
    margin-left: 24px;
}

.phoneLink {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.removeMargin {
    margin-bottom: 0;
}
.FormEntries {
    padding-right: 40px !important;
}

input[type="text"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="select"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="number"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="date"]::-webkit-input-placeholder {
    color: #d5dbd6;
}

input[type="time"]::-webkit-input-placeholder {
    color: #d5dbd6;
}
.CalendarView {
    margin: 25px 25px 25px 25px;
}

.ActionsCard {
    margin-left: 0px !important;
}

.ActionsCardHeader {
    margin-left: 0px !important;
    margin-bottom: 5px !important;
}

.ActionsEmailText {
    color: #8F8F8F;
    margin-left: 51px;
    margin-top: 6px;
}

.ActionsCheckmark {
    color: green;
    margin-left: 6px;
    margin-top: 9px;
}

.ActionsCheckmarkEmail {
    color: green;
    margin-top: 9px;
    margin-right: 3px;
}

.ActionsFailureX {
    color: red;
    margin-left: 3px;
    margin-top: 9px;    
}

.ActionsMarkText {
    color: #8F8F8F;
    margin-left: 20px;
    margin-top: 6px;
}

.ActionsMarkTextNumber {
    color: #8F8F8F;
    margin-left: 5px;
    margin-top: 6px;
}

.ClipboardButtons {
    margin-right: 3px;
}
.bartImage {
  display: inline-block;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 32px;
  height: 18px; 
}

.bartText {
  display: inline;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.hr {
  border: 2px solid rgb(179, 179, 179);
}

.PostButtonCol {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.vaccineImage {
  display: inline-block;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 32px;
  height: 32px; 
  position: absolute;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.vaccineImageMobile {
  width: 32px;
  height: 32px;
  align-content: right;
  justify-content: right;
}

.cardBigInfo {
  display: block;
  font-size: 26px;
  font-weight: 300;
}

.cardMediumInfo {
  display: block;
  font-size: 20px;
  font-weight: 300;
}

.cardSmallInfo {
  display: block;
  font-size: 18px;
  font-weight: 300;
}
.ButtonCol {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.h2 {
    margin-top: 0px;
    border: 1px solid red;
    outline: 5px dotted green;
  }

.banner {
    background-color:#007bff;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
}

.customDashboardContainer {
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;
}

.customDashboardContainer1 {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 150px;
    margin-right: 150px;
}

.bigButton {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.returnButton {
  margin-top: 10px;
  background-color: #f1f1f1;
  color: black;
}

.pulse {
    margin:100px;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #cca92c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 5s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
.bigButton {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.modifiedSpan {
    display: block;
}

.PostButtonCol {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    /* justify-content: start; */
}

.topMobileContainer {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}


a {
    margin-left: 15px;
    margin-bottom: 10px;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}
  
a:hover {
    background-color: #ddd;
    color: black;
}
  
.previous {
    background-color: #f1f1f1;
    color: black;
}

.cardBigInfoMobile {
    display: block;
    font-size: 20px;
    font-weight: 300;
}

.cardMediumInfoMobile {
    display: block;
    font-size: 18px;
    font-weight: 300;
}

.cardSmallInfoMobile {
    display: block;
    font-size: 16px;
    font-weight: 300;
}

  
.customDashboardContainer {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 500px;
    margin-right: 500px;
}

.customDashboardContainerMobile {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.submitButton {
    margin-left: 15px;
    margin-bottom: 10px;
}

.feedbackIcon {
    margin-bottom: 5px;
    margin-left: 5px;
}
.FullOpacity {
    opacity: 100 !important;
}

.OriginalValue {
    width: 50%;
    margin-top: 7px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 14px;
}

.PencilIcon {
    width: 20px;
    height: 20px;
    margin-top: 4px;
}

.NewValue {
    margin-top: -8px;
    margin-right: 20px;
    margin-bottom: 0px !important;
}

.RemoveRowMargin {
    margin-bottom: 10px;
    margin-top: -5px;
}

.ButtonSpacing {
    margin-left: 5px;
}

.ErrorMessage {
    margin-left: 20px;
}

.ButtonLine {
    margin-left: 0px !important;
    margin-bottom: 5px;
    width: 100%;
}

.BooleanButton {
    float: right;
}

.ProfileCard {
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}
.removeRowMargin {
    margin-bottom: 0px;
}

.cardMargins {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 8px;
    padding-top: 10px;
    padding-right: 8px;
    padding-bottom: 10px;
}

.reviewCard {
    margin-left: 5px;
    margin-right: 5px;
}
.mmaImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    width: 150px;
    height: 85px;
  }
